import React from 'react'
import {Link} from 'react-router-dom'
import classnames from 'classnames'
import navigationConfig from '../../../../../configs/navigationConfig'
import SideMenuGroup from './SideMenuGroup'
import {Badge} from 'reactstrap'
import {ChevronRight} from 'react-feather'
import {history} from '../../../../../history'
import axios from 'axios'
import {generateRefreshToken} from '../../../../../serviceWorker'

const common_navbar = [
	{
		id: 'dashboard',
		type: 'item',
		menucode: 1,
		icon: <i className='fa fa-tachometer  icon_style'></i>,
		navLink: '/Dashboard',
		permissions: ['admin', 'editor'],
	},
	{
		id: 'Employer Management',
		menucode: 2,
		type: 'collapse',
		icon: <i className='fa fa-users icon_style'></i>,
	},
	{
		id: 'Approval',
		menucode: 11,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/approval/Employer_Approval_List',
	},
	{
		id: 'Rejected Employers',
		menucode: 49,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/approval/Rejected_Employers',
	},
	{
		id: 'Deleted Employer',
		menucode: 43,
		// menucode: 11,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/management/Deleted_employer_list',
	},
	{
		id: 'Approved Employers',
		menucode: 12,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/management/EmployerList',
	},
	{
		id: 'Approved Employers',
		menucode: 51,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/management/EmailEmployerList',
	},
	{
		id: 'Employee Management',
		menucode: 3,
		// type: "item",
		type: 'collapse',
		icon: <i className='fa fa-id-badge icon_style'></i>,
		permissions: ['admin', 'editor'],
		// navLink: "/Employee_List"
	},
	{
		id: 'Employee List',
		menucode: 44,
		type: 'item',
		icon: <i className='fa fa-id-badge icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/Employee_List',
	},
	{
		id: 'Deleted Employee',
		menucode: 45,
		type: 'item',
		icon: <i className='fa fa-id-badge icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/Deleted_employee',
	},
	{
		id: 'Applied Employee List',
		menucode: 54,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/Applied_Employee_List',
	},

	{
		id: 'Invited Employee List',
		menucode: 55,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/Invited_Employee_List',
	},
	{
		id: 'Job Management',
		menucode: 4,
		type: 'collapse',
		icon: <i className='fa fa-briefcase icon_style'></i>,
	},
	{
		id: 'Pending Approval',
		menucode: 13,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/approval/Job_List',
	},

	//New one
	{
		id: 'Approved Job Posts',
		menucode: 41,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/approval/Job_List_approved',
	},
	//New one
	{
		id: 'Rejected Job posts',
		menucode: 40,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/approval/Job_List_Rej',
	},
	{
		id: 'Government Jobs',
		menucode: 14,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/jobs/GovernmentJobs',
	},
	{
		id: 'Job Package',
		menucode: 15,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/jobs/JobPackage',
	},
	{
		id: 'Masters',
		menucode: 6,
		type: 'collapse',
		icon: <i className='fa fa-cogs  icon_style'></i>,
	},
	{
		id: 'Designation',
		menucode: 17,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/Designation',
	},
	{
		id: 'User Role',
		menucode: 18,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/UserRole',
	},
	{
		id: 'User',
		menucode: 19,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/UserMaster',
	},

	{
		id: 'State',
		menucode: 20,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/State',
	},
	{
		id: 'District',
		menucode: 21,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/District',
	},
	{
	  id: "City/Town",
	  menucode: 22,
	  type: "item",
	  icon: <i className='fa fa-angle-right  icon_style'></i>,
	  permissions: ["admin", "editor"],
	  navLink: "/masters/City"
	},
	{
		id: 'Taluk',
		menucode: 46,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/Taluk',
	},
	{
		id: 'Educational Qualification',
		menucode: 23,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/EducationalQualification',
	},
	{
		id: 'Specialization',
		menucode: 24,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/Specialization',
	},
	{
		id: 'Qualification & Specialization Mapping',
		menucode: 25,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/QasMapping',
	},
	{
		id: 'Industry / Sector',
		menucode: 26,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/IndustryTypes',
	},
	{
		id: 'Job Function',
		menucode: 27,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/JobFunction',
	},
	{
		id: 'Job Role',
		menucode: 28,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/JobRole',
	},

	{
		id: 'Skill',
		menucode: 29,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/Skill',
	},
	{
		id: 'Skill Mapping',
		menucode: 39,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/Skillmapping',
	},
	{
		id: 'Government Organisation',
		menucode: 30,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/GovernmentOrganizations',
	},
	{
		id: 'Facility',
		menucode: 31,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/Facility',
	},
	{
		id: 'Welcome Screen',
		menucode: 32,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/Welcome_Screen',
	},
	{
		id: 'Splash Screen',
		menucode: 33,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/masters/Splash_Screen',
	},
	{
		id: 'SMS Notification',
		menucode: 7,
		type: 'collapse',
		icon: <i className='fa fa-paper-plane  icon_style'></i>,
	},
	{
		id: 'SMS Template',
		menucode: 34,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/sms_notification/SMS_Template',
	},
	{
		id: 'Send SMS',
		menucode: 35,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/sms_notification/SMS_List',
	},

	{
		id: 'Grievances',
		menucode: 5,
		type: 'collapse',
		icon: <i className='fa fa-envelope  icon_style'></i>,
	},
	{
		id: 'Abuse Reporting',
		menucode: 16,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/reporting/Abuse_Management_List',
	},
	{
		id: 'Reports',
		menucode: 8,
		type: 'collapse',
		icon: <i className='fa fa-print  icon_style'></i>,
	},
	{
		id: 'Subscription Payment',
		menucode: 36,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/reports/Subscription_Payment',
	},
	{
		id: 'Login Activity',
		menucode: 50,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/reports/LoginActivity',
	},
	{
		id: 'Shortlisted Employees',
		menucode: 37,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/reports/Shortlisted_Employees',
	},
	{
		id: 'Employee Subscription',
		menucode: 53,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/reports/Employee_Subscription',
	},
	{
		id: 'News & Events',
		menucode: 9,
		type: 'item',
		icon: <i className='fa fa-bullhorn  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/news/News',
	},
	{
		id: 'Contact',
		menucode: 38,
		type: 'item',
		icon: <i className='fa fa-phone  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/Contact_us',
		// navLink: "/subscription_contactus"
	},
	{
		id: 'Subscription Contact',
		menucode: 42,
		type: 'item',
		icon: <i className='fa fa-phone  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/subscription_contactus',
	},
	{
		id: 'Settings',
		menucode: 10,
		type: 'item',
		icon: <i className='fa fa-gear  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/Settings',
	},
	{
		id: 'Push Notification',
		menucode: 47,
		type: 'item',
		icon: <i className='fa fa-paper-plane  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/push_notification/Push_Notification_List',
	},
	{
		id: 'Flash Jobs',
		menucode: 48,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/jobs/FlashJobsList',
	},
	{
		id: 'Lead List',
		menucode: 52,
		type: 'item',
		icon: <i className='fa fa-user  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/Lead_List',
	},
	{
		id: 'Enrolled Employees List',
		menucode: 56,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/reports/Employees_applied',
	},
	{
		id: 'Disenrolled Employees List',
		menucode: 57,
		type: 'item',
		icon: <i className='fa fa-angle-right  icon_style'></i>,
		permissions: ['admin', 'editor'],
		navLink: '/reports/Employees_not_applied',
	},
]

class SideMenuContent extends React.Component {
	constructor(props) {
		super(props)

		this.parentArr = []
		this.collapsedPath = null
		this.redirectUnauthorized = () => {
			history.push('/misc/not-authorized')
		}
	}
	state = {
		ipurl: global.config.geturl.ipaddress.adminUserUrl,
		flag: true,
		isHovered: false,
		activeGroups: [],
		currentActiveGroup: [],
		tempArr: [],
		menu_local: localStorage.getItem('usermenulist'),
		final_menu: [],
		tempmenu: [],
		menu_list: [],
		// navigationConfig: [
		//   {
		//     id: "dashboard",
		//     menucode: 1,
		//     title: "Dashboard",
		//     parentmenucode: 0,
		//     type: "item",
		//     icon: <i className='fa fa-tachometer  icon_style'></i>,
		//     permissions: ["admin", "editor"],
		//     navLink: "/Dashboard"
		//   },
		//   {
		//     id: "employermanagement",
		//     menucode: 2,
		//     title: "Employer Management",
		//     parentmenucode: 0,
		//     type: "collapse",
		//     icon: <i className='fa fa-users icon_style'></i>,
		//     children: [
		//       {
		//         id: "employeerapproval",
		//         menucode: 11,
		//         title: "Approval",
		//         parentmenucode: 2,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/approval/Employer_Approval_List"
		//       },
		//       {
		//         id: "employeerapproved",
		//         menucode: 12,
		//         title: "Approved Employers",
		//         parentmenucode: 2,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/management/EmployerList",
		//       },
		//     ]
		//   },
		//   {
		//     id: "employee",
		//     menucode: 3,
		//     title: "Employee Management",
		//     parentmenucode: 0,
		//     type: "item",
		//     icon: <i className='fa fa-id-badge icon_style'></i>,
		//     permissions: ["admin", "editor"],
		//     navLink: "/Employee_List"
		//   },
		//   {
		//     id: "jobmanagement",
		//     menucode: 4,
		//     title: "Job Management",
		//     parentmenucode: 0,
		//     type: "collapse",
		//     icon: <i className='fa fa-briefcase icon_style'></i>,
		//     children: [
		//       {
		//         id: "jobpackage",
		//         menucode: 15,
		//         title: "Job Package",
		//         parentmenucode: 4,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/jobs/JobPackage"
		//       },
		//       {
		//         id: "governmentjobs",
		//         menucode: 14,
		//         title: "Government Jobs",
		//         parentmenucode: 4,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/jobs/GovernmentJobs"
		//       },
		//       {
		//         id: "jobapproval",
		//         menucode: 13,
		//         title: "Approval",
		//         parentmenucode: 4,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/approval/Job_List"
		//       },
		//     ]
		//   },
		//   {
		//     id: "masters",
		//     menucode: 6,
		//     title: "Masters",
		//     parentmenucode: 0,
		//     type: "collapse",
		//     icon: <i className='fa fa-cogs  icon_style'></i>,
		//     children: [
		//       {
		//         id: "designation",
		//         menucode: 17,
		//         title: "Designation",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/Designation"
		//       },
		//       {
		//         id: "userrole",
		//         menucode: 18,
		//         title: "User Role",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/UserRole"
		//       },
		//       {
		//         id: "usermaster",
		//         menucode: 19,
		//         title: "User",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/UserMaster"
		//       },

		//       {
		//         id: "state",
		//         menucode: 20,
		//         title: "State",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/State",
		//       },
		//       {
		//         id: "district",
		//         menucode: 21,
		//         title: "District",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/District",
		//       },
		//       {
		//         id: "city",
		//         menucode: 22,
		//         title: "City/Town",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/City"
		//       },
		//       {
		//         id: "educationalqualification",
		//         menucode: 23,
		//         title: "Educational Qualification",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/EducationalQualification"
		//       },
		//       {
		//         id: "specialization",
		//         menucode: 24,
		//         title: "Specialization",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/Specialization"
		//       },
		//       {
		//         id: "qualification_specialization",
		//         menucode: 25,
		//         title: "Qualification & Specialization Mapping",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/QasMapping"
		//       },
		//       {
		//         id: "industrytypes",
		//         menucode: 26,
		//         title: "Industry / Sector",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/IndustryTypes"
		//       },
		//       {
		//         id: "jobfunction",
		//         menucode: 27,
		//         title: "Job Function",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/JobFunction"
		//       },
		//       {
		//         id: "jobrole",
		//         menucode: 28,
		//         title: "Job Role",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/JobRole"
		//       },

		//       {
		//         id: "skill",
		//         menucode: 29,
		//         title: "Skill",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/Skill"
		//       },
		//       // {
		//       //   id: "jobgroup",
		//       //   title: "Job Group",
		//       //   type: "item",
		//       //   icon: <i className='fa fa-angle-right  icon_style'></i>,
		//       //   permissions: ["admin", "editor"],
		//       //   navLink: "/masters/JobGroup"
		//       // },

		//       {
		//         id: "governmentorganization",
		//         menucode: 30,
		//         title: "Government Organization",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/GovernmentOrganizations"
		//       },
		//       {
		//         id: "facility",
		//         menucode: 31,
		//         title: "Facility",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/Facility"
		//       },
		//       {
		//         id: "splashscreen",
		//         menucode: 32,
		//         title: "Splash Screen",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/Splash_Screen"
		//       },
		//       {
		//         id: "welcomescreen",
		//         menucode: 33,
		//         title: "Welcome Screen",
		//         parentmenucode: 6,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/masters/Welcome_Screen"
		//       },

		//     ]
		//   },
		//   {
		//     id: "sms_notification",
		//     menucode: 7,
		//     title: "SMS Notification",
		//     parentmenucode: 0,
		//     type: "collapse",
		//     icon: <i className='fa fa-paper-plane  icon_style'></i>,
		//     children: [
		//       {
		//         id: "sms_template",
		//         menucode: 34,
		//         title: "SMS Template",
		//         parentmenucode: 7,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/sms_notification/SMS_Template"
		//       },
		//       // {
		//       //   id: "send_sms",
		//       // menucode: 35,
		//       //   title: "Send SMS",
		//       //parentmenucode:7,
		//       //   type: "item",
		//       //   icon: <i className='fa fa-angle-right  icon_style'></i>,
		//       //   permissions: ["admin", "editor"],
		//       //   navLink: "/sms_notification/SMS_List"
		//       // },
		//     ]
		//   },

		//   {
		//     id: "reporting",
		//     menucode: 5,
		//     title: "Grievances",
		//     parentmenucode: 0,
		//     type: "collapse",
		//     icon: <i className='fa fa-envelope  icon_style'></i>,
		//     children: [
		//       {
		//         id: "abusemangement",
		//         menucode: 16,
		//         title: "Abuse Reporting",
		//         parentmenucode: 5,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/reporting/Abuse_Management_List"
		//       }
		//     ]
		//   },
		//   {
		//     id: "reports",
		//     menucode: 8,
		//     title: "Reports",
		//     parentmenucode: 0,
		//     type: "collapse",
		//     icon: <i className='fa fa-print  icon_style'></i>,
		//     children: [
		//       {
		//         id: "subscriptionpayment",
		//         menucode: 34,
		//         title: "Subscription Payment",
		//         parentmenucode: 8,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/reports/Subscription_Payment"
		//       },
		//       {
		//         id: "shortlistedemployees",
		//         menucode: 35,
		//         title: "Shortlisted Employees",
		//         parentmenucode: 8,
		//         type: "item",
		//         icon: <i className='fa fa-angle-right  icon_style'></i>,
		//         permissions: ["admin", "editor"],
		//         navLink: "/reports/Shortlisted_Employees"
		//       },
		//       // {
		//       //   id: "salesreport",
		//       //   title: "Sales Report",
		//       //   type: "item",
		//       //   icon: <i className='fa fa-angle-right  icon_style'></i>,
		//       //   permissions: ["admin", "editor"],
		//       //   navLink: "/reports/Sales_Report"
		//       // }
		//     ]
		//   },
		//   {
		//     id: "news",
		//     menucode: 9,
		//     title: "News & Events",
		//     parentmenucode: 0,
		//     type: "item",
		//     icon: <i className='fa fa-bullhorn  icon_style'></i>,
		//     permissions: ["admin", "editor"],
		//     navLink: "/news/News"
		//   },
		//   {
		//     id: "Contact",
		//     menucode: 0,
		//     title: "Contact",
		//     parentmenucode: 0,
		//     type: "item",
		//     icon: <i className='fa fa-phone  icon_style'></i>,
		//     permissions: ["admin", "editor"],
		//     navLink: "/Contact_us"
		//   },
		//   {
		//     id: "settings",
		//     menucode: 10,
		//     title: "Settings",
		//     parentmenucode: 0,
		//     type: "item",
		//     icon: <i className='fa fa-gear  icon_style'></i>,
		//     permissions: ["admin", "editor"],
		//     navLink: "/Settings"
		//   }
		// ]
	}

	handleGroupClick = (id, parent = null, type = '') => {
		let open_group = this.state.activeGroups
		let active_group = this.state.currentActiveGroup
		let temp_arr = this.state.tempArr
		// Active Group to apply sidebar-group-active class
		if (type === 'item' && parent === null) {
			active_group = []
			temp_arr = []
		} else if (type === 'item' && parent !== null) {
			active_group = []
			if (temp_arr.includes(parent)) {
				temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length)
			} else {
				temp_arr = []
				temp_arr.push(parent)
			}
			active_group = temp_arr.slice(0)
		} else if (type === 'collapse' && parent === null) {
			temp_arr = []
			temp_arr.push(id)
		} else if (type === 'collapse' && parent !== null) {
			if (active_group.includes(parent)) {
				temp_arr = active_group.slice(0)
			}
			if (temp_arr.includes(id)) {
				// temp_arr.splice(temp_arr.indexOf(id), 1)
				temp_arr.splice(temp_arr.indexOf(id), temp_arr.length)
			} else {
				temp_arr.push(id)
			}
		} else {
			temp_arr = []
		}

		if (type === 'collapse') {
			// If open group does not include clicked group item
			if (!open_group.includes(id)) {
				// Get unmatched items that are not in the active group
				let temp = open_group.filter(function (obj) {
					return active_group.indexOf(obj) === -1
				})
				// Remove those unmatched items from open group
				if (temp.length > 0 && !open_group.includes(parent)) {
					open_group = open_group.filter(function (obj) {
						return !temp.includes(obj)
					})
				}
				if (open_group.includes(parent) && active_group.includes(parent)) {
					open_group = active_group.slice(0)
				}
				// Add group item clicked in open group
				if (!open_group.includes(id)) {
					open_group.push(id)
				}
			} else {
				// If open group includes click group item, remove it from open group
				open_group.splice(open_group.indexOf(id), 1)
			}
		}
		if (type === 'item') {
			open_group = active_group.slice(0)
		}

		this.setState({
			activeGroups: open_group,
			tempArr: temp_arr,
			currentActiveGroup: active_group,
		})
	}

	initRender = parentArr => {
		this.setState({
			activeGroups: parentArr.slice(0),
			currentActiveGroup: parentArr.slice(0),
			flag: false,
		})
	}

	componentDidMount() {
		if (localStorage.getItem('usercode')) this.load_bind()
		this.initRender(this.parentArr[0] ? this.parentArr[0] : [])
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.activePath !== this.props.activePath) {
			if (this.collapsedMenuPaths !== null) {
				this.props.collapsedMenuPaths(this.collapsedMenuPaths)
			}

			this.initRender(this.parentArr[0] ? this.parentArr[this.parentArr.length - 1] : [])
		}
	}

	userlistflatten(array) {
		var response_menu = []
		if (this.state.menu_local && this.state.menu_local.length > 0) {
			response_menu = JSON.parse(this.state.menu_local).map(m => {
				return m
			})
		}
		for (let i = 0; i < array.length; i++) {
			let item = array[i]
			if (item.hasOwnProperty('children') && item.children && item.children.length > 0) {
				let child = item.children
				let has_user = false
				if (response_menu && response_menu.length > 0) {
					// has_user = response_menu.includes(item.menucode&& e.access!='' )
					let val = response_menu.find(e => e.menucode === item.menucode && e.access != '')
					if (val) has_user = true
				}
				if (child.length > 0 && has_user) {
					if (this.state.final_menu && this.state.final_menu.length > 0) {
						let has_menu = this.state.final_menu.find(e => e.menucode === item.menucode && e.access != '')
						if (!has_menu) {
							let menu = common_navbar.find(e => e.menucode === item.menucode && e.access != '')
							let type = '',
								navlink = '',
								icon = ''
							if (menu) {
								type = menu.type
								navlink = menu.navLink
								icon = menu.icon
							}
							this.state.final_menu.push({
								id: item.menuname,
								menucode: item.menucode,
								title: item.menuname,
								parentmenucode: item.parentmenucode,
								orderval: item.orderval,
								type: type,
								navLink: navlink,
								icon: icon,
							})
							this.setState({final_menu: this.state.final_menu})
						}
						const childarr = this.userlistflatten(child)
					} else {
						let menu = common_navbar.find(e => e.menucode === item.menucode && e.access != '')
						let type = '',
							navlink = '',
							icon = ''
						if (menu) {
							type = menu.type
							navlink = menu.navLink
							icon = menu.icon
						}
						this.state.final_menu.push({
							id: item.menuname,
							menucode: item.menucode,
							title: item.menuname,
							parentmenucode: item.parentmenucode,
							orderval: item.orderval,
							type: type,
							navLink: navlink,
							icon: icon,
						})

						this.setState({final_menu: this.state.final_menu})
						const childarr = this.userlistflatten(child)
					}
				}
				//   else {
				//    let menu = common_navbar.find(e => e.menucode === item.menucode);
				//     let type = '', navlink = '', icon = '';
				//     if (menu) {
				//       type = menu.type;
				//       navlink = menu.navLink;
				//       icon = menu.icon;
				//     }
				//     this.state.final_menu.push({ id: item.menuname, menucode: item.menucode, title: item.menuname, parentmenucode: item.parentmenucode, type: type, navLink: navlink, icon: icon });
				// }
			} else {
				let has_user_new = response_menu.find(e => e.menucode === item.menucode && e.access != '')
				// let has_user_new = response_menu.includes(item.menucode)
				if (has_user_new) {
					if (this.state.final_menu && this.state.final_menu.length > 0) {
						let has_menu = this.state.final_menu.find(e => e.menucode === item.menucode && e.access != '')
						if (!has_menu) {
							let menu = common_navbar.find(e => e.menucode === item.menucode && e.access != '')
							let type = '',
								navlink = '',
								icon = ''
							if (menu) {
								type = menu.type
								navlink = menu.navLink
								icon = menu.icon
							}
							this.state.final_menu.push({
								id: item.menuname,
								menucode: item.menucode,
								title: item.menuname,
								parentmenucode: item.parentmenucode,
								orderval: item.orderval,
								type: type,
								navLink: navlink,
								icon: icon,
							})
							this.setState({final_menu: this.state.final_menu})
						}
					} else {
						let menu = common_navbar.find(e => e.menucode === item.menucode && e.access != '')
						let type = '',
							navlink = '',
							icon = ''
						if (menu) {
							type = menu.type
							navlink = menu.navLink
							icon = menu.icon
						}
						this.state.final_menu.push({
							id: item.menuname,
							menucode: item.menucode,
							title: item.menuname,
							parentmenucode: item.parentmenucode,
							orderval: item.orderval,
							type: type,
							navLink: navlink,
							icon: icon,
						})
						this.setState({final_menu: this.state.final_menu})
					}
				}
			}
		}
		let temp_new = []
		this.state.final_menu.forEach(d => {
			if (d.parentmenucode === 0) {
				temp_new.push({
					id: d.id,
					menucode: d.menucode,
					title: d.title,
					parentmenucode: d.parentmenucode,
					orderval: d.orderval,
					type: d.type,
					icon: d.icon,
					navLink: d.navLink,
					icon: d.icon,
					children: [],
				})
			}
		})

		this.state.final_menu.forEach(d => {
			temp_new.forEach(i => {
				if (i.menucode === d.parentmenucode) {
					i.children.push({
						id: d.id,
						menucode: d.menucode,
						title: d.title,
						parentmenucode: d.parentmenucode,
						orderval: d.orderval,
						type: d.type,
						navLink: d.navLink,
						icon: d.icon,
					})
				}
			})
		})
		this.setState({tempmenu: (this.state.tempmenu = temp_new)})
	}

	load_bind() {
		var accessToken = localStorage.getItem('accessToken')

		axios({
			method: 'Post',
			url: this.state.ipurl + 'userrole/load',
			crossdomain: true,
			headers: {
				Authorization: 'Bearer ' + accessToken,
				'Content-Type': 'application/json',
			},
			params: {
				usercode: localStorage.getItem('usercode'),
				ipaddress: localStorage.getItem('ipaddress'),
			},
		}).then(async response => {
			if (response.data.status == 401) {
				await generateRefreshToken().then(result => {
					if (result == 1) {
						this.load_bind()
					} else {
						// alert('Unauthorized')
					}
				})
			} else {
				let menudata = response.data.userrole_json_result.menu_list
				let menulist = []
				menudata.forEach(d => {
					if (d.parentmenucode === 0) {
						menulist.push({menucode: d.menucode, menuname: d.menuname, parentmenucode: d.parentmenucode, children: [], orderval: d.orderval})
					}
				})

				//************ Push  Child Menu Details in Array Variable**************
				menudata.forEach(d => {
					menulist.forEach(i => {
						if (i.menucode === d.parentmenucode) {
							i.children.push({menucode: d.menucode, menuname: d.menuname, parentmenucode: d.parentmenucode, orderval: d.orderval})
						}
					})
				})
				this.setState({menu_list: (this.state.menu_list = menulist)})
				this.state.menu_list.sort(this.GetSortOrder('orderval'))
				menulist.forEach(i => {
					if (i.children.length > 0) {
						i.children.sort(this.GetSortOrder('orderval'))
					}
				})
				this.userlistflatten(menulist)
			}
		})
	}
	GetSortOrder(prop) {
		return function (a, b) {
			if (a[prop] > b[prop]) {
				return 1
			} else if (a[prop] < b[prop]) {
				return -1
			}
			return 0
		}
	}

	render() {
		const menuItems = this.state.tempmenu.map(item => {
			const CustomAnchorTag = item.type === 'external-link' ? `a` : Link
			// checks if item has groupheader
			if (item.type === 'groupHeader') {
				return (
					<li className='navigation-header' key={`group-header-${item.groupTitle}`}>
						<span>{item.groupTitle}</span>
					</li>
				)
			}

			let renderItem = (
				<li
					className={classnames('nav-item', {
						'has-sub': item.type === 'collapse',
						open: this.state.activeGroups.includes(item.id),
						'sidebar-group-active': this.state.currentActiveGroup.includes(item.id),
						hover: this.props.hoverIndex === item.id,
						active:
							(this.props.activeItemState === item.navLink && item.type === 'item') ||
							(item.parentOf && item.parentOf.includes(this.props.activeItemState)),
						disabled: item.disabled,
					})}
					key={item.id}
					onClick={e => {
						e.stopPropagation()
						if (item.type === 'item') {
							this.props.handleActiveItem(item.navLink)
							this.handleGroupClick(item.id, null, item.type)
							if (this.props.deviceWidth <= 1200 && item.type === 'item') {
								this.props.toggleMenu()
							}
						} else {
							this.handleGroupClick(item.id, null, item.type)
						}
					}}
				>
					<CustomAnchorTag
						to={item.filterBase ? item.filterBase : item.navLink && item.type === 'item' ? item.navLink : ''}
						href={item.type === 'external-link' ? item.navLink : ''}
						className={`d-flex ${item.badgeText ? 'justify-content-between' : 'justify-content-start'}`}
						onMouseEnter={() => {
							this.props.handleSidebarMouseEnter(item.id)
						}}
						onMouseLeave={() => {
							this.props.handleSidebarMouseEnter(item.id)
						}}
						key={item.id}
						onClick={e => {
							return item.type === 'collapse' ? e.preventDefault() : ''
						}}
						target={item.newTab ? '_blank' : undefined}
					>
						<div className='menu-text'>
							{item.icon}

							<span className='menu-item menu-title' id={item.title}>
								{/* <FormattedMessage id={item.title} /> */}
								{item.title}
							</span>
						</div>

						{item.badge ? (
							<div className='menu-badge'>
								<Badge color={item.badge} className='mr-1' pill>
									{item.badgeText}
								</Badge>
							</div>
						) : (
							''
						)}
						{item.type === 'collapse' ? <ChevronRight className='menu-toggle-icon' size={13} /> : ''}
					</CustomAnchorTag>
					{item.type === 'collapse' ? (
						<SideMenuGroup
							group={item}
							handleGroupClick={this.handleGroupClick}
							activeGroup={this.state.activeGroups}
							handleActiveItem={this.props.handleActiveItem}
							activeItemState={this.props.activeItemState}
							handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
							activePath={this.props.activePath}
							hoverIndex={this.props.hoverIndex}
							initRender={this.initRender}
							parentArr={this.parentArr}
							triggerActive={undefined}
							currentActiveGroup={this.state.currentActiveGroup}
							permission={this.props.permission}
							currentUser={this.props.currentUser}
							redirectUnauthorized={this.redirectUnauthorized}
							collapsedMenuPaths={this.props.collapsedMenuPaths}
							toggleMenu={this.props.toggleMenu}
							deviceWidth={this.props.deviceWidth}
						/>
					) : (
						''
					)}
				</li>
			)

			if (item.navLink && item.collapsed !== undefined && item.collapsed === true) {
				this.collapsedPath = item.navLink
				this.props.collapsedMenuPaths(item.navLink)
			}

			if (
				item.type === 'collapse' ||
				item.type === 'external-link' ||
				(item.type === 'item' && item.permissions && item.permissions.includes(this.props.currentUser)) ||
				item.permissions === undefined
			) {
				return renderItem
			} else if (item.type === 'item' && item.navLink === this.props.activePath && !item.permissions.includes(this.props.currentUser)) {
				return this.redirectUnauthorized()
			}
		})
		return <React.Fragment>{menuItems}</React.Fragment>
	}
}
export default SideMenuContent
